import React from 'react';
import { Card, Col, Divider, Flex, Image, Row, Tabs } from 'antd';
import { BreadCrumbBx, ContentBx } from '../../../components';
import ProfileSettings from '../Settings/GeneralSettings';
import Skills from './Skills';
import SocialLinks from './SocialLinks';
import BasicInfo from './BasicInfo';
import Projects from './Projects';

const Profile = () => {
  const handleTabsChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: 'Overview',
      children: <Overview/>,
    },
    {
      key: '2',
      label: 'Basic Info',
      children: <BasicInfo/>,
    },
    {
      key: '3',
      label: 'Social Links',
      children: <SocialLinks/>,
    },
    {
      key: '4',
      label: 'Skills',
      children: <Skills/>,
    },
    {
      key: '5',
      label: 'Projects',
      children: <Projects/>,
    },
  ];


  return (
    <div id='profile'>
      <BreadCrumbBx currentPage="My Profile" />

      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={handleTabsChange}
      />

    </div>
  );
};

const Overview = () => {
  return (
    <Row gutter="20">
        <Col span="8">
          <Card style={{marginTop: 24}} className='profile-info'>
            <Flex justify="center">
              <Image
              rootClassName="profile-img"
                src="https://media.licdn.com/dms/image/D4D03AQHT0QvrefWmZQ/profile-displayphoto-shrink_200_200/0/1676099702083?e=1724889600&v=beta&t=ZNKP6p9XrxQwrg2GoV_cARNcl2aUHYBrzdp1DDpbRWE"
              />
            </Flex>
            <h3 className='name'>Ahmed Hamza</h3>
            <h4 className='job'>Frontend Developer</h4>
          </Card>
          <Card style={{marginTop: 24}}>
            Contact Info
            <Divider/>
            Skills
            <Divider/>
            Social Links
          </Card>
        </Col>
        <Col span="16">
          <ContentBx>
            <h2>Hero Section</h2>
            <h2>About Section</h2>
            <h2>Work Section</h2>
          </ContentBx>
        </Col>
      </Row>
  )
}

export default Profile;
