import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserPref, updateUserPref } from '../../../services/api/user';
import { setLogin } from '../../../store/authSlice';
import { Button, Form, message, Radio, Space } from 'antd';
import { ContentBx } from '../../../components';

const Preferences = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getUserPref();
    if (!result) {
      message.error('Internal Server Error');
    } else {
      setData(result);
    }
  };
  useEffect(() => {
    const { theme_preference, theme_color } = data;
    form.setFieldsValue({
      theme_preference,
      theme_color,
    });
  }, [data]);

  const onFinish = async (values) => {
    setLoading(true);
    const updatedData = await updateUserPref(values);

    if (!updatedData) {
      message.error('Failed to update!');
      setLoading(false);
      return;
    }

    message.success('Updated Successfully!');
    dispatch(setLogin(updatedData));
    setLoading(false);
  };

  return (
    <ContentBx>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{
          maxWidth: 500,
        }}
      >
        <Form.Item
          label="Theme Preference"
          name="theme_preference"
          rules={[
            {
              required: true,
              message: 'Please select your Theme Preference!',
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="light">Light</Radio>
              <Radio value="dark">Dark</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Theme Colors"
          name="theme_color"
          rules={[
            {
              required: true,
              message: 'Please select your Theme Color!',
            },
          ]}
        >
          <Radio.Group optionType="button">
            <Space>
              {[
                '#1A97F5',
                '#03C9D7',
                '#7352FF',
                '#FF5C8E',
                '#1E4DB7',
                '#FB9678',
              ]?.map((color, index) => (
                <Radio
                  key={index}
                  value={color}
                  style={{
                    backgroundColor: color,
                    fontSize: 0,
                    borderRadius: '50%',
                  }}
                >
                  {color}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save Preferences
        </Button>
      </Form>
    </ContentBx>
  );
};

export default Preferences;
