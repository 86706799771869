import {
  UilUser,
  UilCreateDashboard,
  UilSetting,
  UilStore,
  UilPower,
  UilEye
} from '@iconscout/react-unicons';

export const navigation = [
  { key: '1', icon: UilCreateDashboard, name: 'Dashboard', slug: '/admin' },
  { key: '2', icon: UilEye, name: 'View Live Page', slug: '#' },
  { key: '3', icon: UilUser, name: 'Profile', slug: '/admin/profile' },
  {
    key: '4',
    icon: UilStore,
    name: 'Themes',
    slug: '/admin/themes',
    disabled: true,
  },
  { key: '5', icon: UilSetting, name: 'Settings', slug: '/admin/settings' },
  { key: '6', icon: UilPower, name: 'Logout' },
];