import { ID, Query } from 'appwrite'
import { account, appwriteConfig, databases, storage } from '../appwrite'
import axios from 'axios'
const { databaseId, userCollectionId, storageId } = appwriteConfig

const githubKey = process.env.REACT_APP_GITHUB

export async function userAvailability(username) {
  try {
    const session = await databases.listDocuments(
      databaseId,
      userCollectionId,
      [Query.equal('username', username)],
    )
    return session
  } catch (error) {
    console.log('userAvailability :: error', error?.message)
  }
}

// Update User
export async function updateUser(id, user) {
  try {
    const newUser = await databases.updateDocument(
      databaseId,
      userCollectionId,
      id,
      user,
    )
    return newUser
  } catch (error) {
    console.log('updateUser :: error', error?.message)
  }
}

// Get Preferences
export async function getUserPref() {
  try {
    const newUser = await account.getPrefs()
    return newUser
  } catch (error) {
    console.log('getUserPref :: error', error?.message)
  }
}

// Update Preferences
export async function updateUserPref(pref) {
  try {
    const newUser = await account.updatePrefs(pref)
    return newUser
  } catch (error) {
    console.log('updateUserPref :: error', error?.message)
  }
}

// Upload Image
export async function uploadImage(file) {
  try {
    const newUser = await storage.createFile(storageId, ID.unique(), file)
    return { success: newUser }
  } catch (error) {
    console.log('uploadImage :: error', error?.message)
    return { error: error?.message }
  }
}

export async function getGithubProjects(username) {
  try {
    const repos = await axios.get(
      `https://api.github.com/users/${username}/repos`,
    )

    // const repos = await axios.get('https://api.github.com/user', {
    //   headers: {
    //     Authorization: `Bearer ${githubKey}`,
    //   },
    // });

    debugger

    if(!repos) return

    const reposArr = Promise.all(repos?.data?.map(async (repo) => ({
      id: repo?.id,
      name: repo?.name,
      description: repo?.description,
      topics: repo?.topics,
      github: repo?.url,
      web: repo?.html_url,
      languages: await getGithubLang(repo.languages_url),
    })))
    return reposArr

  } catch (error) {
    console.log(error)
  }
}

export async function getGithubLang(url) {
  try {
    const lang = await axios.get(url)
    // debugger
    return lang?.data ? Object.keys(lang?.data) : null
  } catch (error) {
    console.log(error)
  }
}

export async function getLinkedinData(url) {
  try {
    const result = await axios.get('http://66afcdf18debfece76fd.appwrite.global/', {username: "ahmedhamzaarif"})
    debugger
    return result
  } catch (error) {
    console.log(error)
  }
}
