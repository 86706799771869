import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './routes'
import { Loader } from './components'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

const App = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Suspense fallback={<Loader />}>
          <AppRoutes />
        </Suspense>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default App
