import React from 'react'
import './StatusLoader.css'
import { Flex } from 'antd'

const StatusLoader = () => {
  return (
    <Flex justify="center" align="center" style={{ minHeight: '100vh' }}>
      <div className="three-body">
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
      </div>
    </Flex>
  )
}

export default StatusLoader
