import React from 'react';
import { ContentBx } from '../../components';
import { Button, Divider, Flex, Image } from 'antd';
import { useSelector } from 'react-redux';
import { UilShare } from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const { name, username, imageUrl, githubUrl, linkedinUrl } = useSelector(
    (state) => state.auth.userData
  );

  return (
    <div id="dashboard">
      <ContentBx>
        <Flex align="center" justify="space-between" gap="10px">
          <Flex align="center" gap="10px" className="profile-info">
            <Image
              rootClassName="profile-img"
              src={imageUrl}
              style={{ maxWidth: 70, borderRadius: 100 }}
            />
            <div>
              <h2 style={{ margin: 0 }} className="name">
                Hi, {name}
              </h2>
              <h4 style={{ margin: 0 }} className="url">
                enginr.me/{username}
              </h4>
            </div>
          </Flex>
          <Button type="primary" icon={<UilShare />}>
            Share
          </Button>
        </Flex>
        {(!githubUrl || !linkedinUrl) && (
          <>
            <Divider />
            <Flex align="center" justify="space-between" gap="10px">
              <p>Please complete your profile.</p>
              <Link to="/admin/settings">
                <Button type="primary">Complete Setup</Button>
              </Link>
            </Flex>
          </>
        )}
      </ContentBx>
    </div>
  );
};

export default Dashboard;
