import { Button, message, Switch } from 'antd'
import React, { useState } from 'react'
import { getGithubProjects } from '../../../services/api/user'
import { Space, Table, Tag } from 'antd'
import { UilGithub, UilWindow } from '@iconscout/react-unicons'

const Projects = () => {
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState([
    {
      id: 661631289,
      name: '404',
      description: null,
      topics: ['404'],
      github: 'https://api.github.com/repos/ahmedhamzaarif/404',
      web: 'https://github.com/ahmedhamzaarif/404',
      languages: ['HTML'],
    },
    {
      id: 555737147,
      name: 'ahmedhamzaarif',
      description: 'Repo for GitHub Profile Home Page',
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/ahmedhamzaarif',
      web: 'https://github.com/ahmedhamzaarif/ahmedhamzaarif',
      languages: [],
    },
    {
      id: 769634931,
      name: 'apihub',
      description:
        'Your own API Hub to learn and master API interaction. Ideal for frontend, mobile dev and backend developers. ',
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/apihub',
      web: 'https://github.com/ahmedhamzaarif/apihub',
      languages: ['JavaScript', 'HTML', 'Dockerfile', 'Shell'],
    },
    {
      id: 657316889,
      name: 'chatbot-smit',
      description: "This repo is created for SMIT's AI & Chatbot course",
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/chatbot-smit',
      web: 'https://github.com/ahmedhamzaarif/chatbot-smit',
      languages: ['JavaScript', 'HTML', 'CSS'],
    },
    {
      id: 480793191,
      name: 'cwa-bootcamp',
      description: 'Converted a Figma ProtoType to Html & Css Website',
      topics: ['css', 'figma', 'figma-to-html', 'html'],
      github: 'https://api.github.com/repos/ahmedhamzaarif/cwa-bootcamp',
      web: 'https://github.com/ahmedhamzaarif/cwa-bootcamp',
      languages: ['HTML', 'CSS'],
    },
    {
      id: 711139277,
      name: 'devathon',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/devathon',
      web: 'https://github.com/ahmedhamzaarif/devathon',
      languages: ['JavaScript', 'HTML', 'CSS'],
    },
    {
      id: 707541222,
      name: 'developer-portfolio',
      description: 'Portfolio for Developers',
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/developer-portfolio',
      web: 'https://github.com/ahmedhamzaarif/developer-portfolio',
      languages: ['HTML', 'CSS', 'JavaScript'],
    },
    {
      id: 708977129,
      name: 'flowise',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/flowise',
      web: 'https://github.com/ahmedhamzaarif/flowise',
      languages: [
        'TypeScript',
        'JavaScript',
        'CSS',
        'SCSS',
        'HTML',
        'Dockerfile',
        'Shell',
        'Batchfile',
      ],
    },
    {
      id: 579673337,
      name: 'gsap-slider',
      description: 'This slider was made with GSAP',
      topics: ['gsap', 'js'],
      github: 'https://api.github.com/repos/ahmedhamzaarif/gsap-slider',
      web: 'https://github.com/ahmedhamzaarif/gsap-slider',
      languages: ['HTML', 'CSS', 'JavaScript'],
    },
    {
      id: 712019475,
      name: 'imageRecognition',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/imageRecognition',
      web: 'https://github.com/ahmedhamzaarif/imageRecognition',
      languages: ['HTML'],
    },
    {
      id: 624103891,
      name: 'javascript-smit',
      description: 'This repo is for Assignments of JS Crash Course',
      topics: ['javascript'],
      github: 'https://api.github.com/repos/ahmedhamzaarif/javascript-smit',
      web: 'https://github.com/ahmedhamzaarif/javascript-smit',
      languages: ['JavaScript', 'HTML', 'CSS'],
    },
    {
      id: 571208408,
      name: 'mini-projects',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/mini-projects',
      web: 'https://github.com/ahmedhamzaarif/mini-projects',
      languages: ['HTML', 'JavaScript', 'CSS'],
    },
    {
      id: 699236288,
      name: 'modern-website',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/modern-website',
      web: 'https://github.com/ahmedhamzaarif/modern-website',
      languages: ['HTML', 'CSS', 'JavaScript'],
    },
    {
      id: 693075510,
      name: 'next-practice',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/next-practice',
      web: 'https://github.com/ahmedhamzaarif/next-practice',
      languages: ['JavaScript', 'CSS'],
    },
    {
      id: 693076058,
      name: 'next-practice-2',
      description: null,
      topics: [
        'context-api',
        'react-hooks',
        'reactjs',
        'usecontext',
        'useeffect',
      ],
      github: 'https://api.github.com/repos/ahmedhamzaarif/next-practice-2',
      web: 'https://github.com/ahmedhamzaarif/next-practice-2',
      languages: ['JavaScript', 'CSS'],
    },
    {
      id: 693076479,
      name: 'next-todolist',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/next-todolist',
      web: 'https://github.com/ahmedhamzaarif/next-todolist',
      languages: ['JavaScript', 'CSS'],
    },
    {
      id: 724436190,
      name: 'nextjs-dashboard',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/nextjs-dashboard',
      web: 'https://github.com/ahmedhamzaarif/nextjs-dashboard',
      languages: ['TypeScript', 'JavaScript', 'CSS'],
    },
    {
      id: 773567146,
      name: 'ng-balloon-pop',
      description: null,
      topics: ['angular', 'angular-signals'],
      github: 'https://api.github.com/repos/ahmedhamzaarif/ng-balloon-pop',
      web: 'https://github.com/ahmedhamzaarif/ng-balloon-pop',
      languages: ['TypeScript', 'HTML', 'SCSS', 'JavaScript'],
    },
    {
      id: 773194856,
      name: 'ng-mini-udemy',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/ng-mini-udemy',
      web: 'https://github.com/ahmedhamzaarif/ng-mini-udemy',
      languages: ['HTML', 'TypeScript', 'SCSS', 'JavaScript'],
    },
    {
      id: 776544759,
      name: 'ng-notes-app',
      description: null,
      topics: ['angular'],
      github: 'https://api.github.com/repos/ahmedhamzaarif/ng-notes-app',
      web: 'https://github.com/ahmedhamzaarif/ng-notes-app',
      languages: ['TypeScript', 'CSS', 'HTML'],
    },
    {
      id: 662426545,
      name: 'passGen',
      description: 'PassGen is a Random Password Generator',
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/passGen',
      web: 'https://github.com/ahmedhamzaarif/passGen',
      languages: ['HTML', 'CSS', 'JavaScript'],
    },
    {
      id: 708872279,
      name: 'quran-gpt',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/quran-gpt',
      web: 'https://github.com/ahmedhamzaarif/quran-gpt',
      languages: ['JavaScript', 'CSS', 'HTML'],
    },
    {
      id: 701526614,
      name: 'react-contextLocalStorage',
      description: null,
      topics: [],
      github:
        'https://api.github.com/repos/ahmedhamzaarif/react-contextLocalStorage',
      web: 'https://github.com/ahmedhamzaarif/react-contextLocalStorage',
      languages: ['JavaScript', 'HTML', 'CSS'],
    },
    {
      id: 695029407,
      name: 'react-currencyConverter',
      description: null,
      topics: ['custom-hooks', 'reactjs', 'useid'],
      github:
        'https://api.github.com/repos/ahmedhamzaarif/react-currencyConverter',
      web: 'https://github.com/ahmedhamzaarif/react-currencyConverter',
      languages: ['JavaScript', 'CSS', 'HTML'],
    },
    {
      id: 701383324,
      name: 'react-miniContext',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/react-miniContext',
      web: 'https://github.com/ahmedhamzaarif/react-miniContext',
      languages: ['JavaScript', 'CSS', 'HTML'],
    },
    {
      id: 717997733,
      name: 'react-multi-lang',
      description: null,
      topics: ['formik', 'i18next', 'multi-language-support', 'react', 'yup'],
      github: 'https://api.github.com/repos/ahmedhamzaarif/react-multi-lang',
      web: 'https://github.com/ahmedhamzaarif/react-multi-lang',
      languages: ['JavaScript', 'CSS', 'HTML'],
    },
    {
      id: 694982718,
      name: 'react-passwordGenerator',
      description: null,
      topics: [
        'react-hooks',
        'reactjs',
        'usecallback',
        'useeffect',
        'useref',
        'usestate',
      ],
      github:
        'https://api.github.com/repos/ahmedhamzaarif/react-passwordGenerator',
      web: 'https://github.com/ahmedhamzaarif/react-passwordGenerator',
      languages: ['JavaScript', 'HTML', 'CSS'],
    },
    {
      id: 702335235,
      name: 'react-reduxTooltitTodos',
      description: null,
      topics: ['reactjs', 'redux', 'redux-toolkit'],
      github:
        'https://api.github.com/repos/ahmedhamzaarif/react-reduxTooltitTodos',
      web: 'https://github.com/ahmedhamzaarif/react-reduxTooltitTodos',
      languages: ['JavaScript', 'CSS', 'HTML'],
    },
    {
      id: 701064837,
      name: 'react-router',
      description: null,
      topics: ['react', 'react-router', 'react-router-dom', 'reactjs'],
      github: 'https://api.github.com/repos/ahmedhamzaarif/react-router',
      web: 'https://github.com/ahmedhamzaarif/react-router',
      languages: ['JavaScript', 'HTML', 'CSS'],
    },
    {
      id: 701456489,
      name: 'react-themeswitcher',
      description: null,
      topics: [],
      github: 'https://api.github.com/repos/ahmedhamzaarif/react-themeswitcher',
      web: 'https://github.com/ahmedhamzaarif/react-themeswitcher',
      languages: ['JavaScript', 'CSS', 'HTML'],
    },
  ])

  const showProject = ({ id }) => {
    const projectsArr = projects.map((item) =>
      item?.id === id ? { ...item, show: !item.show } : item,
    )
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'name',
      render: (text) => text.replace('-', ' '),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (desc) => <div className="desc">{desc}</div>,
    },
    {
      title: 'Topics',
      dataIndex: 'topics',
      key: 'topics',
      render: (tags) => (
        <>
          {tags?.map((tag) => {
            let color = tag.length > 5 ? 'geekblue' : 'green'
            if (tag === 'loser') {
              color = 'volcano'
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: 'Languages',
      key: 'languages',
      dataIndex: 'languages',
      render: (tags) => (
        <>
          {tags?.map((tag) => {
            let color = tag.length > 5 ? 'geekblue' : 'green'
            if (tag === 'loser') {
              color = 'volcano'
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            )
          })}
        </>
      ),
    },
    {
      title: 'Url',
      key: 'action',
      render: (_, { github, web }) => (
        <Space size="middle" className='links'>
          <a>
            <UilGithub />
          </a>
          <a>
            <UilWindow />
          </a>
        </Space>
      ),
    },
    {
      title: 'Show/Hide',
      key: 'action',
      render: (_, record) => (
        <Switch defaultChecked onChange={() => showProject()} />
      ),
    },
  ]

  const getProjects = async () => {
    const repos = await getGithubProjects('ahmedhamzaarif')
    console.log(repos)
    debugger

    if (repos) {
      // setProjects(reposArr)
    } else {
      message.error('Not Found')
    }
  }
  return (
    <div>
      <h1>Projects</h1>
      <Button type="primary" onClick={getProjects}>
        <UilGithub /> Sync New Projects
      </Button>

      <Table className="table table-responsive" columns={columns} dataSource={projects} loading={loading} />
    </div>
  )
}

export default Projects
