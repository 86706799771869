import { Client, Account, Databases, Storage, Avatars } from 'appwrite'

export const appwriteConfig = {
    url: process.env.REACT_APP_APPWRITE_PROJECT_URL,
    projectId: process.env.REACT_APP_APPWRITE_PROJECT_ID,
    databaseId: process.env.REACT_APP_APPWRITE_DATABASE_ID,
    storageId: process.env.REACT_APP_APPWRITE_STORAGE_ID,
    userCollectionId: process.env.REACT_APP_APPWRITE_USER_COLLECTION_ID,
    portfolioCollectionId: process.env.REACT_APP_APPWRITE_PORTFOLIO_COLLECTION_ID,
    profileCollectionId: process.env.REACT_APP_APPWRITE_PROFILE_COLLECTION_ID,
}

export const client = new Client()

client.setProject(appwriteConfig.projectId)
client.setEndpoint(appwriteConfig.url)

export const account = new Account(client)
export const databases = new Databases(client)
export const storage = new Storage(client)
export const avatars = new Avatars(client)