import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home, Login, Register, Welcome } from '../pages';
import { AuthLayout } from '../pages/layout';
import Portfolio from '../pages/portfolio/Profile';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      {/* <Route path="/portfolio" element={<Portfolio />} /> */}
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/welcome" element={<Welcome />} />
      </Route>

      <Route path="*" element={<Navigate to='/' />} />
    </Routes>
  );
};

export default AuthRoutes;
