import React from 'react'
import { CommentOutlined, CustomerServiceOutlined } from '@ant-design/icons'
import { FloatButton } from 'antd'

const FloatingButton = () => (
  <>
    <FloatButton.Group
      trigger="click"
      type="primary"
      style={{
        right: 24,
      }}
      icon={<CustomerServiceOutlined />}
    >
      <FloatButton tooltip="Feedback" />
      <FloatButton tooltip="Suggest a feature" icon={<CommentOutlined />} />
    </FloatButton.Group>
  </>
)

export default FloatingButton
