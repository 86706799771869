import React from 'react'

const Profile = ({user}) => {
  return (
    <>
    {user ? <h2>Hi {user}</h2> : "Not Found"}
    </>
  )
}

export default Profile