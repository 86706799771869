import { Button, Card, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { ImageUploader } from '../../../services/utils/utils';
import { uploadImage } from '../../../services/api/user';

const BasicInfo = () => {
  const [form] = Form.useForm();
  const [editImg, setEditImg] = useState("")

  const onFinish = async (values) => {
    if (editImg) {
      const {success, error}  = await uploadImage(editImg)
      debugger
      if (success) {
        message.success('success')
        // update user
        // {...data, image: success?.$id}
      } else {
        message.error(error)
      }
    }
  };

  return (
    <>
      <Card className='content-card' title="Basic Info">
        <Form form={form} onFinish={onFinish} layout='vertical' size='large'>
          <Form.Item name="dp">
            <ImageUploader setEditImg={setEditImg} />
          </Form.Item>
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="role" label="Role">
            <Input />
          </Form.Item>
          <Form.Item name="bio" label="Bio">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>
          <Form.Item name="location" label="Location">
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Card>
      <Card className='content-card' title="Company Info">
        <Form form={form} onFinish={onFinish} layout='vertical' size='large'>
          <Form.Item name="company_name" label="Company Name">
            <Input />
          </Form.Item>
          <Form.Item name="company_web" label="Company Website">
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default BasicInfo;
