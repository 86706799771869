import React from 'react'
import { Layout, theme } from 'antd'

const ContentBx = ({ children }) => {
  const { Content } = Layout
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken()

  return (
    <>
      <Content className="content-bx">
        <div
          style={{
            padding: 24,
            minHeight: 100,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {children}
        </div>
      </Content>
    </>
  )
}

export default ContentBx
