import React, { useState } from 'react';
import { Card, Flex, Select, Tag } from 'antd';
import { UilTwitter } from '@iconscout/react-unicons';
// import options from '../../../data/skillList.json'
import techSkills from '../../../data/techSkills.json';
import softSkills from '../../../data/softSkills.json';

const Skills = () => {
  const [techSkillsArr, setTechSkillsArr] = useState(['html', 'css', 'js']);
  const [softSkillArr, setSoftSkillArr] = useState([
    'problem-solving',
    'time-management',
  ]);

  // Generic function to flatten skills array
  const flattenSkills = (skills) => {
    return skills.flatMap((group) => group.options);
  };

  // Flatten all available skills
  const allTechSkills = flattenSkills(techSkills);
  const allSoftSkills = softSkills;

  // Generic function to get selected skills
  const getSelectedSkills = (allSkills, selectedSkills) => {
    return allSkills.filter((skill) => selectedSkills.includes(skill?.value));
  };

  // Get selected skills objects
  const selectedTechSkills = getSelectedSkills(allTechSkills, techSkillsArr);
  const selectedSoftSkills = getSelectedSkills(allSoftSkills, softSkillArr);

  const handleChangeTechSkills = (value) => {
    setTechSkillsArr(value);
  };

  const handleChangeSoftSkills = (value) => {
    setSoftSkillArr(value);
  };

  return (
    <>
      <Card className="content-card skills" title="Technical Skills">
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Select Skills"
          onChange={handleChangeTechSkills}
          optionLabelProp="label"
          defaultValue={techSkillsArr}
          size="large"
          virtual={false}
          maxTagCount="responsive"
        >
          {techSkills.map((group) => (
            <Select.OptGroup key={group.label} label={group.label}>
              {group.options.map((option) => (
                <Select.Option
                  key={option.value}
                  value={option.value}
                  label={option.label}
                >
                  <span>{option.label}</span>
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
        <Flex gap="4px" wrap style={{ marginTop: 10 }}>
          {selectedTechSkills?.map((skill, index) => (
            <Tag key={index} icon={<UilTwitter />} color="#55acee">
              {skill?.label}
            </Tag>
          ))}
        </Flex>
      </Card>
      <Card className="content-card" title="Soft Skills">
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Select Skills"
          onChange={handleChangeSoftSkills}
          options={softSkills}
          defaultValue={softSkillArr}
          size="large"
        />
        <Flex gap="4px" wrap style={{ marginTop: 10 }}>
          {selectedSoftSkills?.map((skill, index) => (
            <Tag key={index} color="#55acee">
              {skill?.label}
            </Tag>
          ))}
        </Flex>
      </Card>
    </>
  );
};

export default Skills;
