import { ID, OAuthProvider, Query } from 'appwrite'
import {
  appwriteConfig,
  account,
  databases,
  storage,
  avatars,
} from '../appwrite'

const { databaseId, userCollectionId } = appwriteConfig

// --------------------------------- Authentication ---------------------------------

// SignUp
export async function createUserAccount(user) {
  try {
    const newAccount = await account.create(
      ID.unique(),
      user.email,
      user.password,
      user.name,
    )

    if (!newAccount) throw Error

    const avatarUrl = avatars.getInitials(user.name)
    const newUser = await saveUserToDB({
      accountId: newAccount.$id,
      name: newAccount.name,
      email: newAccount.email,
      username: user.username,
      imageUrl: avatarUrl,
    })

    return newUser
  } catch (error) {
    console.log('createUserAccount :: error', error?.message)
    return error
  }
}

export async function createOAuthUser() {
  try {
    const newAccount = await account.createOAuth2Session(
      OAuthProvider.Google,
      'http://localhost:3000/admin', // redirect here on success
      'http://localhost:3000/login', // redirect here on failure
    )

    debugger
    if (!newAccount) throw Error
    console.log(newAccount)

    // const avatarUrl = avatars.getInitials(user.name);
    // const newUser = await saveUserToDB({
    //   accountId: newAccount.$id,
    //   name: newAccount.name,
    //   email: newAccount.email,
    //   username: user.username,
    //   imageUrl: avatarUrl,
    // });

    // return newUser;
  } catch (error) {
    console.log('createUserAccount :: error', error?.message)
    return error
  }
}

// Save User To DB
export async function saveUserToDB(user) {
  try {
    const newUser = await databases.createDocument(
      databaseId,
      userCollectionId,
      ID.unique(),
      user,
    )
    return newUser
  } catch (error) {
    console.log('saveUserToDB :: error', error?.message)
  }
}

// SignIn
export async function signInAccount(user) {
  try {
    const session = await account.createEmailPasswordSession(
      user.email,
      user.password,
    )
    return session
  } catch (error) {
    console.log('signInAccount :: error', error?.message)
  }
}

// Magic URL
export async function magicUrlSignIn(email) {
  try {
    const session = await account.createMagicURLToken(
      ID.unique(),
      email,
      'http://localhost:3000/welcome',
    )
    console.log(session)
    return session
  } catch (error) {
    console.log(error)
  }
}

// Magic URL Session
export async function magicUrlSession(userId, secret) {
  try {
    const session = await account.createSession(userId, secret)
    console.log(session)
    return session
  } catch (error) {
    console.log(error)
  }
}

// Get Account
export async function getAccount() {
  try {
    const currentAccount = await account.get()
    return currentAccount
  } catch (error) {
    console.log('getAccount :: error', error?.message)
  }
}

// Get User
export async function getCurrentUser() {
  try {
    const currentAccount = await getAccount()
    debugger
    if (!currentAccount) throw Error

    const currentUser = await databases.listDocuments(
      databaseId,
      userCollectionId,
      [Query.equal('accountId', currentAccount.$id)],
    )

    if (!currentUser) throw Error

    return currentUser.documents[0]
  } catch (error) {
    console.log('getCurrentUser :: error', error?.message)
  }
}

// SignOut
export async function signOutAccount() {
  try {
    // const session = await account.deleteSession("current");
    const session = await account.deleteSessions()
    return session
  } catch (error) {
    console.log('signOutAccount :: error', error?.message)
  }
}

// ChangePassword
export async function changePassword(newPass, oldPass) {
  try {
    const session = await account.updatePassword(newPass, oldPass)
    return session
  } catch (error) {
    console.log('changePassword :: error', error?.message)
  }
}
