import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { setLogin, setLogout } from '../../store/authSlice';
import { getCurrentUser } from '../../services/api/auth';

const AuthLayout = () => {
 

  return (
    <div id='auth'>
        <Outlet />
    </div>
  );
};

export default AuthLayout;