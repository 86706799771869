import { ConfigProvider, theme } from 'antd';
import React, { useEffect, useState } from 'react';

const defaultData = {
  fontFamily: 'Jost, sans-serif',
  borderRadius: 6,
  colorPrimary: '#009DDC',
  colorTextBase: "#252525",
  Button: {
    colorPrimary: '#009DDC',
    lineHeight: '1.75rem'
  },
};


const AntDConfig = ({ children }) => {
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    setData(defaultData)
  }, [defaultData])

  return (
    <ConfigProvider
    // componentSize="large"
      theme={{
        token: {
          fontFamily: data.fontFamily,
          colorPrimary: data.colorPrimary,
          borderRadius: data.borderRadius,
          colorTextBase: data.colorTextBase,
        },
        components: {
          Button: {
            colorPrimary: data.Button?.colorPrimary,
            algorithm: data.Button?.algorithm,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntDConfig;
