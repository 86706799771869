import React from 'react';
import { AdminLayout } from '../pages/layout';
import { Dashboard, Home, Profile, Settings } from '../pages';
import { Navigate, Route, Routes } from 'react-router-dom';
import Portfolio from '../pages/portfolio/Profile';

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="admin" element={<AdminLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="settings" element={<Settings />} />
        <Route path="profile" element={<Profile />} />
      </Route>
      <Route path="/home" element={<Home />} />
      {/* <Route path="/portfolio" element={<Portfolio />} /> */}
      <Route path="*" element={<Navigate to='/admin' />} />
    </Routes>
  );
};

export default ProtectedRoutes;
