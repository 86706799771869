import { Button, Card, Divider, Form, Input, message } from 'antd'
import React, { useState } from 'react'
import {
  createOAuthUser,
  createUserAccount,
  getCurrentUser,
  signInAccount,
} from '../../../services/api/auth'
import { Link, useNavigate } from 'react-router-dom'
import SocialAuth from './SocialAuth'
import { userAvailability } from '../../../services/api/user'
import { setLogin } from '../../../store/authSlice'
import { useDispatch } from 'react-redux'
import { UilGoogle } from '@iconscout/react-unicons'

const Register = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const onFinish = async (values) => {
    setLoading(true)
    const newUser = await createUserAccount(values)

    if (!newUser) {
      setLoading(false)
      return message.error('SignUp failed. Please try again.')
    }

    const session = await signInAccount({
      email: values.email,
      password: values.password,
    })

    if (!session) {
      setLoading(false)
      return message.error('Signin failed. Please try again.')
    }

    const isLoggedIn = await getCurrentUser()

    if (isLoggedIn) {
      setLoading(false)
      dispatch(setLogin(isLoggedIn))
      form.resetFields()
      navigate('/admin')
    } else {
      setLoading(false)
      return message.error('Sign up failed. Please try again.')
    }
  }

  const checkUserName = async (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Username is required.'))
    }
    const { total } = await userAvailability(value)
    if (total > 0) {
      return Promise.reject(
        new Error('Username already exists. Please try again.'),
      )
    } else {
      return Promise.resolve()
    }
  }

  const googleAuth = async () => {
    await createOAuthUser()
  }
  return (
    <>
      <div className="logo dark">
        <img src="/logo.png" alt="" />
      </div>
      <Card title="Register" className="auth-card">
        <div className="o-auth">
          <Button
            type="default"
            size="large"
            icon={<UilGoogle />}
            block
            onClick={googleAuth}
          >
            Continue with Google
          </Button>
          <Divider>or</Divider>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish} size="large">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input type="text" placeholder="Your Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true },
              { type: 'email', message: 'Invalid Email' },
            ]}
          >
            <Input type="email" placeholder="Your Email" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: "You can't leave this field empty." },
              {
                min: 8,
                message: 'Minimum 8 characters',
              },
            ]}
          >
            <Input.Password type="password" placeholder="Your Password" />
          </Form.Item>
          <Form.Item
            name="username"
            label="Username"
            hasFeedback
            validateDebounce={1000}
            rules={[{ required: true, validator: checkUserName }]}
          >
            <Input type="text" placeholder="Username" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Create Account
            </Button>
          </Form.Item>
          {/* <Divider>Or</Divider> */}
          {/* <SocialAuth /> */}
        </Form>
        <div className="form-footer">
          Already have an account? <Link to="/login">Sign In</Link>
        </div>
      </Card>
    </>
  )
}

export default Register
