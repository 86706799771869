import { Button, Card, Checkbox, Divider, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getCurrentUser, signInAccount } from '../../../services/api/auth';
import { setLogin } from '../../../store/authSlice';
import { useDispatch } from 'react-redux';
import SocialAuth from './SocialAuth';

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const remEmail = localStorage.getItem('remEmail');

  useEffect(() => {
    if (remEmail) {
      form.setFieldsValue({ email: remEmail });
      form.setFieldsValue({ remember: true });
    }
  }, [remEmail]);

  const onFinish = async (values) => {
    setLoading(true);
    const { email, password, remember } = values;
    if (remember) {
      localStorage.setItem('remEmail', email);
    } else {
      localStorage.removeItem('remEmail');
    }

    const session = await signInAccount({
      email,
      password,
    });

    if (!session) {
      setLoading(false);
      return message.error('Signin failed. Please try again.');
    }

    const isLoggedIn = await getCurrentUser();

    if (isLoggedIn) {
      dispatch(setLogin(isLoggedIn));
      setLoading(false);
      form.resetFields();
      navigate('/admin');
      return message.success('LoggedIn Successfully!');
    } else {
      setLoading(false);
      return message.error('Sign in failed. Please try again.');
    }
  };

  return (
    <>
      <div className="logo dark">
        <img src="/logo.png" alt="" />
      </div>
      <Card title="Login" className="auth-card">
        <Form form={form} layout="vertical" onFinish={onFinish} size='large'>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { required: true, message: "You can't leave this field empty." },
              { type: 'email', message: 'Invalid Email' },
            ]}
          >
            <Input type="email" placeholder="Enter your email address" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "You can't leave this field empty." },
              {
                min: 8,
                message: 'Minimum 8 characters',
              },
            ]}
          >
            <Input.Password type="password" placeholder="Enter your password" />
          </Form.Item>
          <div className="form-msg">
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Keep me logged in</Checkbox>
            </Form.Item>
            <Link className="forgot-pass" to="/forgot-password">
              Forgot Password?
            </Link>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Sign In
            </Button>
          </Form.Item>
          {/* <Divider>Or</Divider> */}
          {/* <SocialAuth /> */}
        </Form>
        <div className="form-footer">
          Don't have an account? <Link to="/register">Sign Up</Link>
        </div>
      </Card>
    </>
  );
};

export default Login;
