import React, { useState } from 'react';
import { Button, Card, Form, Input, message } from 'antd';
import { changePassword } from '../../../services/api/auth';
import { validatePassword } from '../../../services/utils/utils';

const AccountSettings = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)

  const onFinish = async (values) => {
    setLoading(true)
    const result = await changePassword(values?.password, values?.old_password);

    if (!result) {
      message.error('Failed to update!');
      setLoading(false)
      return;
    }

    message.success('Password Updated Successfully!');
    setLoading(false)
    form.resetFields()
  };

  return (
    <>
      <Card className="content-card" title="Change Password">
        <Form form={form} size="large" onFinish={onFinish} layout="vertical">
          <Form.Item
            name="old_password"
            label="Old Password"
            rules={[{ min: 8, message: 'Minimum 8 characters' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="password"
            label="New Password"
            rules={[{ min: 8, message: 'Minimum 8 characters' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm Password"
            hasFeedback
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              { validator: (_, value) => validatePassword(form, value) },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="middle" loading={loading}>
            Update Password
          </Button>
        </Form>
      </Card>
      <Card className="content-card" title="Delete Account">
        <p style={{ marginTop: 0 }}>
          Permanently remove your Personal Account and all of its contents from
          the Enginr platform. This action is not reversible, so please continue
          with caution.
        </p>
        <Button type="primary" disabled>Delete Account</Button>
      </Card>
    </>
  );
};

export default AccountSettings;
