import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { ContentBx } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../services/api/user';
import { setLogin } from '../../../store/authSlice';

const GeneralSettings = () => {
  const {
    $id: id,
    username,
    githubUrl,
    linkedinUrl,
  } = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      username,
      github: githubUrl,
      linkedin: linkedinUrl,
    });
  }, []);

  const onFinish = async (values) => {
    const formData = {
      githubUrl: values?.github,
      linkedinUrl: values?.linkedin,
    };
    setLoading(true);
    const updatedData = await updateUser(id, formData);

    if (!updatedData) {
      message.error('Failed to update!');
      setLoading(false);
      return;
    }

    message.success('Updated Successfully!');
    dispatch(setLogin(updatedData));
    setLoading(false);
  };

  return (
    <>
      <ContentBx>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          style={{
            maxWidth: 500,
          }}
          size="large"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input type="text" placeholder="Enter your username" disabled />
          </Form.Item>
          <Form.Item
            label="Github"
            name="github"
            rules={[
              {
                required: true,
                message: 'Please input your Github Username!',
              },
            ]}
          >
            <Input type="text" placeholder="Enter your Github username" />
          </Form.Item>
          <Form.Item
            label="LinkedIn"
            name="linkedin"
            rules={[
              {
                required: true,
                message: 'Please input your LinkedIn Username!',
              },
            ]}
          >
            <Input type="text" placeholder="Enter your LinkedIn username" />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} size='middle'>
            Save
          </Button>
        </Form>
      </ContentBx>
    </>
  );
};

export default GeneralSettings;
