import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Flex, Space, Spin } from 'antd'

const Loader = () => {
  return (
    <Flex justify="center" align="center" style={{ minHeight: 250 }}>
      <Spin indicator={<LoadingOutlined spin />} />
    </Flex>
  )
}

export default Loader
