import React from 'react'
import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'

const BreadCrumbBx = ({ currentPage }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <h3
      style={{
        margin: 0,
        fontSize: 22,
      }}
    >
      {currentPage}
    </h3>
    <Breadcrumb
      separator=<span className="custom-separator"></span>
      items={[
        {
          href: '/',
          title: (
            <>
              <HomeOutlined />
              <span>Dashboard</span>
            </>
          ),
        },
        // {
        //   href: "",
        //   title: (
        //     <>
        //       <UserOutlined />
        //       <span>Application List</span>
        //     </>
        //   ),
        // },
        {
          title: currentPage,
        },
      ]}
    />
  </div>
)
export default BreadCrumbBx
