import { Card, Form, Input } from 'antd'
import React from 'react'

const SocialLinks = () => {
  return (
    <Form size='large' layout="vertical">
    <Card className='content-card' title="Networking">
      <Form.Item label="LinkedIn" name="linkedin">
        <Input/>
      </Form.Item>
      <Form.Item label="Twitter" name="twitter">
        <Input/>
      </Form.Item>
    </Card>
    <Card className='content-card' title="Development">
    <Form.Item label="Stackoverflow" name="Stackoverflow">
        <Input/>
      </Form.Item>
      <Form.Item label="Github" name="github">
        <Input/>
      </Form.Item>
    <Form.Item label="CodePen" name="codepen">
        <Input/>
      </Form.Item>
      <Form.Item label="JSfiddle" name="jsfiddle">
        <Input/>
      </Form.Item>
    </Card>
    <Card className='content-card' title="Writing">
    <Form.Item label="Medium" name="medium">
        <Input/>
      </Form.Item>
      <Form.Item label="Hashnode" name="hashnode">
        <Input/>
      </Form.Item>

    </Card>
    <Card className='content-card' title="Design">
    <Form.Item label="Behance" name="behance">
        <Input/>
      </Form.Item>
      <Form.Item label="Dribble" name="dribble">
        <Input/>
      </Form.Item>

    </Card>
    </Form>
  )
}

export default SocialLinks