import React, { useState } from 'react';
import {
  UilRefresh,
  UilUnlock,
  UilAlignCenterAlt,
} from '@iconscout/react-unicons';
import { Button, Col, Layout, Menu, message, Modal, Row, theme } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { signOutAccount } from '../../services/api/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../../store/authSlice';
import { navigation } from './MenuItems';
import FloatingButton from './FloatingButton';
import { getLinkedinData } from '../../services/api/user';
const { Header, Footer, Sider } = Layout;

const items = navigation.map((item) => ({
  key: item.key,
  icon: React.createElement(item?.icon),
  label: item?.name,
  disabled: item?.disabled,
}));

const AdminLayout = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.userData);
  const user = userData?.name?.split(' ')[0];
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Are you sure?');

  const handleOk = () => {
    setModalText('Signing Out');
    setConfirmLoading(true);
    setTimeout(() => {
      signOut();
      setShowModal(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const signOut = async () => {
    const result = await signOutAccount();
    if (result === undefined) {
      message.error('Logout failed!');
      return;
    }
    message.success('Logout Successfully!');
    dispatch(setLogout());
    navigate('/login');
  };

  const handleMenuClick = ({ key }) => {
    const { slug, name } = navigation.find((item) => item.key === key) || {};
    if (slug) {
      navigate(slug);
    } else if (!slug && name === 'Logout') {
      // signOut();
      setShowModal(true);
    }
    // Task: collapse side menu on responsive
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <Sider
        className="side-menu"
        trigger={<UilAlignCenterAlt />}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log('sidebar :: onBreakpoint :: broken', broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(
            'sidebar :: onCollapse :: collapsed/type',
            collapsed,
            type
          );
        }}
      >
        <div className="logo">
          <img src="/logo-white.png" alt="" />
        </div>
        <Menu
          theme="dark"
          style={{ background: '#000' }}
          mode="inline"
          defaultSelectedKeys={['1']}
          onClick={handleMenuClick}
          items={items}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: '0 20px',
            background: colorBgContainer,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Button type="primary" icon={<UilRefresh />} onClick={getLinkedinData}>
            Update Content
          </Button>
          {/* <Button type="primary" icon={<UilUnlock />}>
            Upgrade
          </Button> */}
        </Header>
        <div
          style={{
            margin: '24px 30px 0',
          }}
        >
          <Row justify="center">
            <Col lg={16} md={20} sm={24}>
              <Outlet />
            </Col>
          </Row>
        </div>
        <Footer
          style={{
            textAlign: 'center',
            marginTop: 'auto',
          }}
        >
          Enginr © {new Date().getFullYear()} | Coded with 💙 by @AhmedHamzaArif
        </Footer>
      </Layout>
      <FloatingButton />

      <Modal
        open={showModal}
        title="Confirm SignOut"
        onOk={handleOk}
        onCancel={() => setShowModal(false)}
        confirmLoading={confirmLoading}
      >
        <p>{modalText}</p>
      </Modal>
    </Layout>
  );
};

export default AdminLayout;
