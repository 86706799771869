import React from 'react';
import { Tabs } from 'antd';
import { BreadCrumbBx } from '../../../components';
import Preferences from './Preferences';
import ProfileSettings from './GeneralSettings';
import AccountSettings from './AccountSettings';

const Settings = () => {
  const handleTabsChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: 'General',
      children: <ProfileSettings />,
    },
    {
      key: '2',
      label: 'Preferences',
      children: <Preferences />,
    },
    {
      key: '3',
      label: 'Account & Security',
      children: <AccountSettings />,
    },
  ];

  return (
    <div id="settings">
      <BreadCrumbBx currentPage="Settings" />
      <Tabs defaultActiveKey="1" items={items} onChange={handleTabsChange} />
    </div>
  );
};

export default Settings;
