import React, { useRef } from 'react'
import './Home.css'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'

const Home = () => {
  const hero = useRef()
  const heroImg = useRef()

  useGSAP(() => {
    const tl = gsap.timeline()

    tl.from('#logo, header a', {
      y: -50,
      opacity: 0,
      duration: .5,
      delay: .5,
      stagger: .5
    })

    gsap.set('#hero h2 span', {
      display: 'inline-block'
    })

    tl.from('#hero h2 span, #hero p, #hero a', {
      y: 50,
      opacity: 0,
      duration: 0.5,
      stagger: 0.5,
    })
  })

  return (
    <div id="home">
      <header>
        <img id="logo" src="/logo.png" alt="Enginr Logo" />
        <Link to="/login">
          <Button type="primary" size="large" shape="round">
            Go To App
          </Button>
        </Link>
      </header>
      <section ref={hero} id="hero">
        <div className="text-bx">
          <h2>
            <span>Build Your Portfolio</span> <br />
            <span>in One Click</span>
          </h2>
          <p>Connect your linkedin & your portfolio is ready.</p>
          <Link to="/login">
            <Button type="primary" size="large" shape="round">
              Get Started for Free
            </Button>
          </Link>
        </div>
        <div ref={heroImg} className="img-bx">
          <span className="text text-1">
            Multiple <br />
            Theme Options
            <img src="/assets/home/arrow1.png" alt="" />
          </span>
          <span className="text text-2">
            Can Edit <br />
            Manually
            <img src="/assets/home/arrow2.png" alt="" />
          </span>
          <span className="text text-3">
            One Click Update
            <img src="/assets/home/arrow3.png" alt="" />
          </span>
          <img src="/assets/home/hero.png" alt="Hero Image" />
        </div>
      </section>
      <footer>Created with 💙 By @AhmedHamzaArif</footer>
    </div>
  )
}

export default Home