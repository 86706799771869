import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProtectedRoutes from './ProtectedRoutes'
import AuthRoutes from './AuthRoutes'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { getCurrentUser } from '../services/api/auth'
import { setLogin, setLogout } from '../store/authSlice'
import StatusLoader from '../components/StatusLoader'
import Portfolio from '../pages/portfolio/Profile'

const AppRoutes = () => {
  const status = useSelector((state) => state.auth.status)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname: path } = useLocation()

  // Sub Domain
  const url = window.location.href || ''
  const [user, setUser] = useState('')

  useEffect(() => {
    const hostname = new URL(url).hostname
    const subdomain = hostname.split('.')[0]
    // console.info('hostname', hostname)

    if (hostname === 'localhost' || hostname === 'enginr.me') {
      setUser('')
      return
    }

    setUser(subdomain)
  }, [url])

  const checkLoggedIn = async () => {
    if (user) {
      setLoading(false)
      return
    }
    if (status) {
      setLoading(false)
      navigate('/admin')
      return
    }
    const userData = await getCurrentUser()
    if (userData) {
      dispatch(setLogin(userData))
      if (path === '/' || path === '/login') {
        navigate('/admin')
      }
    } else {
      dispatch(setLogout())
      if (path.startsWith('/admin')) {
        navigate('/')
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    checkLoggedIn()
  }, [user])

  if (loading) {
    return <StatusLoader />
  }

  return (
    <>
      {user ? (
        <Routes>
          <Route index element={<Portfolio user={user} />} />
        </Routes>
      ) : (
        <>{status ? <ProtectedRoutes /> : <AuthRoutes />}</>
      )}
    </>
  )
}

export default AppRoutes
