import React, { useEffect, useState } from 'react'
import { Button, Divider, Flex, Form, Input, message } from 'antd'
import { UilGoogle, UilGithub, UilEnvelope } from '@iconscout/react-unicons'
import { getCurrentUser, magicUrlSession, magicUrlSignIn } from '../../../services/api/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryParam, StringParam } from 'use-query-params'
import { useDispatch } from 'react-redux'
import { setLogin } from '../../../store/authSlice'

const Welcome = () => {
  const [showEmailInput, setShowEmailInput] = useState(false)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [userId, setUserId] = useQueryParam('userId', StringParam)
  const [secret, setSecret] = useQueryParam('secret', StringParam)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  useEffect(() => {
    if (!userId || !secret) return
    login()
  }, [userId, secret])

  const onFinish = async ({ email }) => {
    setLoading(true)
    const result = await magicUrlSignIn(email)
    if (result) {
      form.resetFields()
      message.success('Url has been sent to your email')
    } else {
      message.error('Something went wrong')
    }
    setLoading(false)
  }

  const login = async () => {
    const session = await magicUrlSession(userId, secret)

    if (!session) {
      setLoading(false);
      return message.error('Signin failed. Please try again.');
    }

    const isLoggedIn = await getCurrentUser();

    if (isLoggedIn) {
      dispatch(setLogin(isLoggedIn));
      navigate('/admin');
      return message.success('LoggedIn Successfully!');
    } else {
      setLoading(false);
      return message.error('Sign in failed. Please try again.');
    }
  }

  return (
    <div style={{ textAlign: 'center', width: 500, margin: 'auto' }}>
      <h2>Welcome to Enginr</h2>
      <p>
        Sign in or create an account. <strong>Its free!</strong>
      </p>
      <Flex gap={10} vertical>
        <Button type="default" size="large" icon={<UilGoogle />} block>
          Continue with Google
        </Button>
        <Button type="default" size="large" icon={<UilGithub />} block>
          Continue with Github
        </Button>
        <Divider>or</Divider>
        {!showEmailInput ? (
          <Button
            type="default"
            size="large"
            icon={<UilEnvelope />}
            block
            onClick={() => setShowEmailInput(!showEmailInput)}
          >
            Continue with Email
          </Button>
        ) : (
          <Form form={form} onFinish={onFinish} layout="vertical" size="large">
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'You cannot leave this field empty',
                },
                { type: 'email', message: 'Please enter a valid email' },
              ]}
            >
              <Input placeholder="Enter your email address" />
            </Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Continue
            </Button>
          </Form>
        )}
      </Flex>
    </div>
  )
}

export default Welcome
